const initMore = () => {
    const more = document.querySelector('.mod_counseling_list .more');

    if (more) {
        const link = more.querySelector('a');
        const handleClick = (e) => {
            e.preventDefault();
            link.classList.add('loading');
            link.removeEventListener('click', handleClick);
            link.innerHTML = link.innerHTML + '<div class="loading"></div>'

            fetch(
                link.href,
                {
                    headers: {
                        'Accept': 'text/html; counseling-items'
                    },
                }
            ).then((response) => {
                response.text().then((content) => {
                    const parent = more.parentNode;
                    parent.removeChild(more);
                    parent.innerHTML = parent.innerHTML + content;
                    initMore();
                });
            });
        };

        link.addEventListener('click', handleClick, false)
    }
};
if (fetch) {
    window.addEventListener('DOMContentLoaded', initMore);
}
