window.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.widget > input.text, .widget > textarea').forEach((el) => {
        if (!el.nextElementSibling || el.nextElementSibling.tagName !== 'LABEL') {
            el.classList.add('no-label');
        }

        if (!el.getAttribute('placeholder')) {
            const detect = () => {
                (el.value || el.validity.badInput) ? el.classList.remove('no-placeholder') : el.classList.add('no-placeholder');
            };

            el.addEventListener('keyup', detect);
            detect();
        }
    });

    document.querySelectorAll('.widget > select').forEach((el) => {
        const update = () => {
            el.classList[el.value ? 'remove' : 'add']('empty');
        };

        el.addEventListener('change', update);
        update();
    });
});
