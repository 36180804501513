import {tns as TinySlider} from 'tiny-slider/src/tiny-slider.module';

import 'tiny-slider/src/tiny-slider.scss';

window.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.sz-nav-topics')) {
        // Randomize order of slides
        const ul = document.querySelector('.sz-nav-topics .level_1');
        for (var i = ul.children.length; i >= 0; i--) {
            ul.appendChild(ul.children[Math.random() * i | 0]);
        }

        TinySlider({
            container: '.sz-nav-topics .level_1',
            items: 1,
            gutter: 9,
            controlsPosition: 'bottom',
            nav: false,
            loop: false,
            edgePadding: 60,
            // speed: 1000,
            // autoplay: true,
            // autoplayButtonOutput: false,
            // autoHeight: false,

            responsive: {
                481: {
                    items: 2,
                    gutter: 16,
                    edgePadding: 60,
                },
                960: {
                    items: 3,
                    gutter: 16,
                    edgePadding: 120,
                }
            },
        });
    }
})
