window.addEventListener('DOMContentLoaded', () => {
    const isMobile = window.matchMedia("(max-width: 959px)");
    const toggle = document.querySelector('.section-header__toggle');
    const submenus = document.querySelectorAll('.section-header__navigation .level_1 > li.submenu');

    const header = document.querySelectorAll('.section-header__navigation li.submenu');
    header.forEach((el) => {
        const title = el.querySelector('strong');
        const menu = el.querySelector('div.submenu');
        let collapsed = false;
        let height = 0;

        const setup = () => {
            collapsed = true;
            height = menu.offsetHeight;
            menu.style.height = '0px';

            title.addEventListener('click', onclick, false);
        };

        const clear = () => {
            collapsed = false;
            menu.style.height = 'auto';

            title.removeEventListener('click', onclick)
        };

        const onclick = () => {
            if (collapsed) {
                menu.style.height = height+'px';
                setTimeout(() => {
                    menu.style.height = 'auto';
                }, 201)
            } else {
                let height = menu.offsetHeight;
                menu.style.height = height+'px';
                setTimeout(() => {
                    menu.style.height = '0px';
                }, 0);
            }
            collapsed = !collapsed;
        }

        toggle.addEventListener('click', () => {
            menu.style.height = '0px';
            collapsed = true;
        }, false);

        isMobile.addListener(() => {
            clear();

            if (isMobile.matches) {
                setup();
            }
        })

        if (isMobile.matches) {
            setup();
        }
    })


    const toggleOverlay = () => {
        document.body.classList.toggle('overlay-visible');
    };

    const initOverlay = () => {
        document.body.classList.remove('has-overlay', 'overlay-visible', 'has-submenu');
        toggle.removeEventListener('click', toggleOverlay);

        if (isMobile.matches) {
            document.body.classList.add('has-overlay');
            toggle.addEventListener('click', toggleOverlay, false);
        }
    }

    isMobile.addListener(() => {
        initOverlay();
    });

    initOverlay();
});
